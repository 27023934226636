import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import WordCounter from '../../tools/WordCounter'

const WordCounterPage = () => (
    <Layout>
        <SEO title="Online Word Counter – Count the Number of Words in a Text" />
		<section className="hero is-primary">
            <div className="hero-body">
                <div className="container is-fluid">
                    <h1 className="title">Word Counter</h1>
                    <h2 className="subtitle">
                        A free and simple online word counter for you to know how many words, unique words, white spaces, and characters, are in your text. This free word counting tool gives an immediate response and is mobile-friendly!
                    </h2>
                </div>
            </div>
        </section>

		<section className="section">
            <div className="container">
				<WordCounter />
            </div>
        </section>

    </Layout>
)

export default WordCounterPage
