import React, { useState } from 'react'
import Textarea from '../components/Textarea'
import tokenizer from 'sbd'

const getWordsFromString = str => {
    return str
        .trim()
		.split(/\s+/)
		.map(w => w.replace(/[\W_]+/g, ` `).trim())
        .filter(a => a.length > 0)
}

const getUniqueWords = (words = []) =>
    words.map(w => w.toLowerCase()).filter((v, i, a) => a.indexOf(v) === i)

const countWhitespacesInString = (str = ``) => str.match(/([\s\t\n])/g)?.length || 0

const getParagraphs = (str = ``) => str.trim().split(/[\r\n]+/)

const getSentences = (str = ``) => tokenizer.sentences(str, {})

const getWordFrequency = (words = []) =>
    words.map(w => w.toLowerCase()).reduce((stats, word) => {
        if (stats.hasOwnProperty(word)) {
            stats[word] = stats[word] + 1
        } else {
            stats[word] = 1
        }
        return stats
    }, {})

const WordCounter = () => {
    const [text, setText] = useState('')
    const [wordCount, setWordcount] = useState(0)
    const [uniqueWordCount, setUniqueWordCount] = useState(0)
    const [charCount, setCharCount] = useState(0)
    const [whitespaceCount, setWhitespaceCount] = useState(0)
    const [wordFrequency, setWordFrequency] = useState({})
    const [paragraphCount, setParagraphCount] = useState(0)
    const [sentenceCount, setSentenceCount] = useState(0)

    const setAndAnalyze = e => {
        const text = e.target.value

        // set text
        setText(text)

        // words
        const words = getWordsFromString(text)
        setWordcount(words.length)

        // unique words
        const uniquewords = getUniqueWords(words)
        setUniqueWordCount(uniquewords.length)

        // chars
        setCharCount(text.length)

        // whitespaces
        setWhitespaceCount(countWhitespacesInString(text))

        // word frequency
        setWordFrequency(getWordFrequency(words))

        // paragraph count
        setParagraphCount(words.length === 0 ? 0 : getParagraphs(text).length)

        // sentence count
        setSentenceCount(getSentences(text).length)
    }

    return (
        <div>
            <div className="level">
                <div className="level-item has-text-centered">
                    <div>
                        <p className="heading">
                            Word{wordCount > 1 ? `s` : ``}
                        </p>
                        <p className="title">{wordCount.toLocaleString()}</p>
                    </div>
                </div>
                <div
                    className="level-item has-text-centered"
                    style={{ opacity: 0.5 }}
                >
                    <div>
                        <p className="heading">
                            Unique Word{uniqueWordCount > 1 ? `s` : ``}
                        </p>
                        <p className="title">
                            {uniqueWordCount.toLocaleString()}
                        </p>
                    </div>
                </div>
                <div
                    className="level-item has-text-centered"
                    style={{ opacity: 0.5 }}
                >
                    <div>
                        <p className="heading">
                            Character{charCount > 1 ? `s` : ``}
                        </p>
                        <p className="title">{charCount.toLocaleString()}</p>
                    </div>
                </div>
                <div
                    className="level-item has-text-centered"
                    style={{ opacity: 0.5 }}
                >
                    <div>
                        <p className="heading">
                            Whitespace{whitespaceCount > 1 ? `s` : ``}
                        </p>
                        <p className="title">
                            {whitespaceCount.toLocaleString()}
                        </p>
                    </div>
                </div>
                <div
                    className="level-item has-text-centered"
                    style={{ opacity: 0.5 }}
                >
                    <div>
                        <p className="heading">
                            Paragraph{paragraphCount > 1 ? `s` : ``}
                        </p>
                        <p className="title">
                            {paragraphCount.toLocaleString()}
                        </p>
                    </div>
                </div>
                <div
                    className="level-item has-text-centered"
                    style={{ opacity: 0.5 }}
                >
                    <div>
                        <p className="heading">
                            Sentence{sentenceCount > 1 ? `s` : ``}
                        </p>
                        <p className="title">
                            {sentenceCount.toLocaleString()}
                        </p>
                    </div>
                </div>
            </div>
            <div className="columns is-desktop" style={{ paddingTop: `2rem` }}>
                <div className="column is-four-fifths">
                    <div className="field">
                        <div className="control">
                            <Textarea
                                id="text"
                                className="textarea"
                                style={{
                                    height: `100vh`,
                                    width: `100%`,
                                    backgroundColor: `#fff`,
                                    padding: `1rem`,
                                    fontFamily: `monospace`,
                                }}
                                value={text}
                                onChange={setAndAnalyze}
								showCopyToClipboard={false}
								placeholder={`Enter your text here.`}
                            />
                        </div>
                    </div>
                </div>
                <div className="column">
                    <div className="table-container">
                        <table className="table is-striped">
							<thead>
								<tr>
									<th>Word</th>
									<th>Count</th>
								</tr>
							</thead>
							<tbody>
                            {Object.entries(wordFrequency).sort((a, b) => b[1] - a[1]).map(
                                ([word, freq]) => {
                                    return (
                                        <tr key={word}>
                                            <td>{word}</td>
                                            <td>{freq}</td>
                                        </tr>
                                    )
                                }
                            )}
							</tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

WordCounter.propTypes = {}

WordCounter.defaultProps = {}

export default WordCounter
